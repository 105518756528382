import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/components/layouts/metaLayout/MetaLayout.vue"),
    children: [
      {
        path: "/",
        component: () =>
          import("@/components/layouts/formLayout/FormLayout.vue"),
        children: [
          {
            path: "/",
            name: "home",
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
              ],
            },
            component: () => import("@/components/pages/home/HomePage.vue"),
          },
          {
            path: "/login",
            component: () => import("@/components/pages/login/Login.vue"),
          },
        ],
      },
      {
        path: "/",
        component: () =>
          import("@/components/layouts/baseLayout/BaseLayout.vue"),
        children: [
          {
            path: "/waypoints",
            name: "waypoints",
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  name: "Полётное задание",
                  path: "/waypoints",
                },
              ],
            },
            component: () =>
              import("@/components/pages/waypoints/WaypointsPage.vue"),
          },
          {
            path: "/sources",
            name: "sources",
            component: () =>
              import("@/components/pages/sources/SourcesPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/sources",
                  name: "Источники данных",
                },
              ],
            },
          },
          {
            path: "/video",
            name: "video2",
            component: () =>
              import("@/components/pages/map/video/VideoPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/video",
                  name: "Видеоаналитика",
                },
              ],
            },
          },
          {
            path: "/map/waypoint",
            name: "create-waypoint",
            component: () =>
              import("@/components/pages/map/waypoint/WaypointPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  name: "Полётное задание",
                  path: "/waypoints",
                },
                {
                  name: "Создание полётного задания",
                  path: "/map/waypoint",
                },
              ],
            },
          },
          {
            path: "/map/waypoint/:id",
            name: "update-waypoint",
            component: () =>
              import("@/components/pages/map/waypoint/WaypointPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  name: "Полётное задание",
                  path: "/waypoints",
                },
                {
                  name: "Редактирование полётного задания",
                  path: null,
                },
              ],
            },
          },
          {
            path: "/map/waypoint/duplicate/:id",
            name: "duplicate-waypoint",
            component: () =>
              import("@/components/pages/map/waypoint/WaypointPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  name: "Полётное задание",
                  path: "/waypoints",
                },
                {
                  name: "Дублирование полётного задания",
                  path: "/map/waypoint",
                },
              ],
            },
          },
          {
            path: "/history",
            name: "history",
            component: () =>
              import("@/components/pages/history/HistoryPage.vue"),
            breadcrumbs: [
              {
                name: "Выбор задачи",
                path: "/",
              },
              {
                name: "Полётное задание",
                path: "/waypoints",
              },
              {
                name: "Редактирование полётного задания",
                path: null,
              },
            ],
          },
          {
            path: "/history",
            name: "history",
            component: () =>
              import("@/components/pages/history/HistoryPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/history",
                  name: "История полётов",
                },
              ],
            },
          },
          {
            path: "/settings",
            name: "settings",
            component: () =>
              import("@/components/pages/settings/SettingsPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/settings",
                  name: "Настройки",
                },
              ],
            },
          },
          {
            path: "/settings/config",
            name: "settings-config",
            component: () =>
              import("@/components/pages/settings/config/ConfigPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/settings",
                  name: "Настройки",
                },
                {
                  path: "/settings/config",
                  name: "Конфигурация платформы",
                },
              ],
            },
          },
          {
            path: "/layers",
            name: "layers",
            component: () => import("@/components/pages/layers/LayersPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/layers",
                  name: "Привязка подложки",
                },
              ],
            },
          },
          {
            path: "/video/:id",
            name: "video",
            component: () =>
              import("@/components/pages/map/video/VideoPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/history",
                  name: "История полетов",
                },
                {
                  path: "/video",
                  name: "Видеоаналитика",
                },
              ],
            },
          },
          {
            path: "/attachments",
            name: "attachments",
            component: () =>
              import("@/components/pages/attachments/AttachmentsPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/attachments",
                  name: "Вложения",
                },
              ],
            },
          },
          {
            path: "/users",
            name: "users",
            component: () => import("@/components/pages/users/UsersPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/users",
                  name: "Пользователи",
                },
              ],
            },
          },
          {
            path: "/jobs",
            name: "jobs",
            component: () => import("@/components/pages/jobs/JobsPage.vue"),
            meta: {
              breadcrumbs: [
                {
                  name: "Выбор задачи",
                  path: "/",
                },
                {
                  path: "/jobs",
                  name: "Фоновые задачи",
                },
              ],
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  next();
});

export default router;
