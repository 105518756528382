import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import router from './router'
import '@/assets/scss/index.scss'
import { createPinia } from 'pinia'
import ru from 'element-plus/es/locale/lang/ru'
import 'ol/ol.css'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const pinia = createPinia()

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app
    .use(pinia)
    .use(router)
    .use(PerfectScrollbar)
    .use(ElementPlus, { locale: ru })
    .mount('#app')
